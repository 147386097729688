import {Component,input,output} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {CartItemQuantityComponent} from '../cart-item-quantity/cart-item-quantity.component';
import {UrlTree} from '@angular/router';

@Component({
	selector:'app-products-slider',
	imports:[
		SharedModule,
		CartItemQuantityComponent
	],
	templateUrl:'./products-slider.component.html',
	styleUrl:'./products-slider.component.scss'
})
export class ProductsSliderComponent{
	products=input<{
		id:string,
		quantity:number,
		name:string,
		price:number,
		slug:string,
		discountPercent?:number|null,
		pictures:{small:string}[],
		deletedAt?:string,
		hidden:boolean
	}[]>();
	productsError=input.required<unknown>();
	productsLoading=input.required<boolean>();
	submitButtonActive=input.required<boolean>();
	urlTree=input<UrlTree>();
	submitButtonActiveO=output<boolean>();
	
}
